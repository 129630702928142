import React, { useEffect, useState } from 'react';
import history from "../config/history";

export default function Download() {
    const [image, setImage] = useState(null)
    useEffect(() => {
        console.log(history.location.state.dataUrl);
        let dataURI = history.location.state.dataUrl
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        console.log("Blob", blob);
        setImage(blob);
    }, [])
    return (
        <div className="outer-cover">
            <div className="banner-strip text-center banner-strip-inner">

                <nav className="d-flex align-items-center ">
                    <a
                        href
                        style={{ cursor: "pointer" }}
                        onClick={() => (window.location.href = "/")}
                        className="nav-brand"
                    >
                        <img src="images/main-logo.png" alt="" />
                    </a>
                    <ul class="ml-auto">
                        <li>
                            <a
                                href
                                style={{ cursor: "pointer" }}
                                onClick={() => (window.location.href = "/")}
                                class=" action"
                            >
                                HOME
                                    </a>
                        </li>
                        <li class="active">
                            <a
                                href
                                style={{ cursor: "pointer" }}
                                onClick={() => history.push("/my-meme")}
                                class=" action"
                            >
                                MY INVITATIONS
                                    </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div role="main" className="editor-outer">
                <div className="editor-con" id="editor-con">
                    <div className="artboard-con">
                        <div
                            className="artboard"
                            id="artboard"
                            style={{ zIndex: 1 }}
                        >
                            <img id="bgimage" src={history.location.state.dataUrl} />
                        </div>
                        {/* <img src={image && image.arrayBuffer} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

