import axios from "axios";

const headerText = { "Access-Control-Allow-Origin": "*", "Keep-Alive": "timeout=15, max=100" };
const ApiCall = {
  postApiCall,
  getApiCall,
  getApiCallNew
};

async function postApiCall(args) {
  let returnVal = "";
  await axios({
    method: args.method,
    url: args.apiLink,
    data: args.data,
    headers: headerText
  })
    .then(response => {
      returnVal = response;
    })
    .catch(error => {
      returnVal = error.response;
    });
  return await returnVal;
}

async function getApiCall(args) {
  let returnVal = "";
  await axios({
    method: args.method,
    url: args.apiLink,
    params: args.data,
    headers: args.headers
  })
    .then(response => {
      returnVal = response;
    })
    .catch(error => {
      returnVal = error.response;
    });
  return await returnVal;
}
async function getApiCallNew(args) {
  let returnVal = "";
  await axios({
    method: args.method,
    url: args.apiLink,
    data: args.data,
    headers: args.headers
  })
    .then(response => {
      console.log("response", response);
      returnVal = response;
    })
    .catch(error => {
      console.log("error", error);
      returnVal = error.response;
    });
  return await returnVal;
}

export default ApiCall;
