export default {
  GET_ALL_DATA_MEME_REQUEST: "GET_ALL_DATA_MEME_REQUEST",
  GET_ALL_DATA_MEME_SUCCESS: "GET_ALL_DATA_MEME_SUCCESS",
  GET_ALL_DATA_MEME_FAILURE: "GET_ALL_DATA_MEME_FAILURE",

  GET_ALL_MEMES_LIST_REQUEST: "GET_ALL_MEMES_LIST_REQUEST",
  GET_ALL_MEMES_LIST_SUCCESS: "GET_ALL_MEMES_LIST_SUCCESS",
  GET_ALL_MEMES_LIST_FAILURE: "GET_ALL_MEMES_LIST_FAILURE",

  MAKE_PAYMENT_REQUEST: "MAKE_PAYMENT_REQUEST",
  MAKE_PAYMENT_SUCCESS: "MAKE_PAYMENT_SUCCESS",
  MAKE_PAYMENT_FAILURE: "MAKE_PAYMENT_FAILURE",

  FETCH_FONT_REQUEST:"FETCH_FONT_REQUEST",
FETCH_FONT_SUCCESS:"FETCH_FONT_SUCCESS",
FETCH_FONT_FAILURE:"FETCH_FONT_FAILURE",

FREE_DOWNLOAD_REQUEST:"FREE_DOWNLOAD_REQUEST",
FREE_DOWNLOAD_SUCCESS:"FREE_DOWNLOAD_SUCCESS",
FREE_DOWNLOAD_FAILURE:"FREE_DOWNLOAD_FAILURE",
};
