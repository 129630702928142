const baseUrl = "https://invitationsapi.esimchavites.com";
// const baseUrl = "https://memeapi.spericorn.com/";
// const baseUrl = process.env.REACT_APP_BASE_URL;  
if (!baseUrl) {
    throw new Error("API Url is not provided.");
}

export default {
    baseUrl,
    getAllMemeCategories: baseUrl + "/user/listCat",
    getAllMemeData: baseUrl + "/user/listImages?",
    makePayment: baseUrl + "/user/charge",
    fetchFonts: baseUrl + "/user/font",
    freeDownload: baseUrl + "/user/listImagesbyId?"
};
