import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Footer from "./Footer";
import homeIndexAction from "../../actions/homeIndexAction";
import Modal from "../common/Modal";
import history from "../config/history";
import Loader from "./Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import apiLink from "../../api/apiLink";

class homeIndex extends Component {
    constructor(props) {
        super(props);
        this.wookmarkRef = React.createRef();
        this.myRef = React.createRef();
        this.state = {
            captionName: "all",
            getCatList: [],
            categoryId: "",
            selectedImage: "",
            tempimg: false,
            loader: false,
            base64: "",
            file: "",
            renderScript: false,
            mainLoader: true,
            errImg: "",
            limit: 1000,
            offset: 0,
            loadScript: false,
            memeCount: 10,
            errorStatus: false,
            adminMemes: []
        };
        this.modalClose = React.createRef();
    }

    componentDidMount = async () => {
        this.myRef.current.click();
        const categoryName = localStorage.getItem("categoryName");
        let adminMemes = JSON.parse(localStorage.getItem("adminSavedMemes"));
        if (adminMemes === undefined || adminMemes === null)
            adminMemes = []
        await this.setState({
            adminMemes
        })
        localStorage.setItem("categoryName", categoryName);
        const categoryId = localStorage.getItem("categoryId");
        await this.props.getAllCatagoryData();
        this.setState({ categoryId: categoryId });
        const params = {
            limit: this.state.limit,
            offset: this.state.offset
        };
        if (categoryId) {
            await this.props.getAllMemesList({
                limit: this.state.limit,
                offset: this.state.offset,
                category: categoryId
            });
            localStorage.removeItem("categoryId");
        } else {
            await this.props.getAllMemesList(params);
        }
        await this.refreshStyle();
        this.setState({ mainLoader: false });
    };

    refreshStyle = async () => {
        await this.wookmarkRef.current.click()
    };
    onCategoryChange = async (id, name) => {
        localStorage.setItem("categoryId", id);
        localStorage.setItem("categoryName", name);
        await this.setState({
            categoryId: id,
            limit: 1000,
            offset: 0
        });
        await this.props.getAllMemesList({
            limit: this.state.limit,
            offset: this.state.offset,
            category: this.state.categoryId
        });
        this.refreshStyle();
    };

    async componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.getAllMemeAction.allMemesDetails !==
            this.props.getAllMemeAction.allMemesDetails &&
            this.props.getAllMemeAction.allMemesDetails.success
        ) {
            await this.setState({
                renderScript: true,
                mainLoader: false,
                memeCount: this.props.getAllMemeAction.allMemesDetails.memeListCount
            });
            this.refreshStyle()
        }
        // console.log("This state", this.state, prevState, prevProps)
    }

    _onChange = async e => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file) {
            if (file.type.substring(0, 5) === "image") {
                await this.setState({
                    errImg: ""
                });
            } else {
                await this.setState({
                    errImg: "Please upload a vaild image",
                    errorStatus: true
                });
                // alert(this.state.errImg)
            }
            if (!this.state.errImg) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    await this.setState({
                        file: file,
                        base64: reader.result
                    });
                };
                reader.onloadend = async () => {
                    await this.setState({
                        base64: reader.result
                    });
                };
            }
        }
    };
    editPage = img => {
        localStorage.setItem("selectedImage", JSON.stringify(img));
        // console.log("Image", img);
        this.props.history.push({
            pathname: "/create-meme",
            state: {
                base64: `${apiLink.baseUrl}` + img.path[0].watermark,
                imgProperty: JSON.parse(img.imgProperty),
                edit: "edit",
                img: img
            }
        });
    };

    uploadData = async () => {
        if (!this.state.errImg) {
            if (!this.state.errImg) {
                this.modalClose.current.click();
                let uploadDetails = new FormData();
                setTimeout(() => {
                    history.push({
                        pathname: "/create-meme",
                        state: { imageFile: this.state.file, base64: this.state.base64 }
                    });
                }, 200);
            }
        } else {
            alert(this.state.errImg);
        }
    };

    refresh = () => {
        // console.log("Refresh");
    };

    fetchMoreData = async () => {
        const params = {
            limit: this.state.limit + 5,
            offset: 0,
            category: this.state.categoryId
        };

        await this.setState({
            limit: this.state.limit + 5,
            loadingState: false
        });
        await this.props.getAllMemesList(params);
        /* ------ USED TO REFRESH IMAGE STYLE  ------ */
        await this.refreshStyle()
        /* ------ USED TO REFRESH IMAGE STYLE  ------ */
    };

    selectImage = img => this.setState({ selectedImage: img });

    renderImgModal = () => {
        const { selectedImage } = this.state;
        return (
            <div className="modal custom-modal" id="edit-modal" style={{ display: "block" }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{selectedImage.categoryId.name}</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={() => this.setState({ selectedImage: null })}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="img-cover position-relative">
                                <img
                                    src={`${apiLink.baseUrl}` + selectedImage.path[0].watermark}
                                    alt=""
                                />
                                <div className="edit-row d-flex align-items-center justify-content-center">
                                    <a
                                        onClick={() => {
                                            localStorage.setItem(
                                                "selectedImage",
                                                JSON.stringify(selectedImage)
                                            );
                                            this.props.history.push({
                                                pathname: "/create-meme",
                                                state: { base64: this.state.base64 }
                                            });
                                        }}
                                    >
                                        <span></span>
                                        EDIT THIS MEME
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {
            allMemeDetails: { categoryList },
            allMemesDetails: { catImages }
        } = this.props.getAllMemeAction;

        const { categoryId, selectedImage, adminMemes } = this.state;
        let catName = localStorage.getItem("categoryName");
        let catImagesLength = 0;
        if (catImages === undefined) {
            catImagesLength = 0;
        } else {
            catImagesLength = catImages && catImages.length;
        }
        let countOfMemes = 0;
        const getCount = adminMemes.map((meme) => {
            if (categoryId === "")
                countOfMemes = adminMemes.length;
            else {
                if (categoryId === meme.data.categoryId)
                    countOfMemes++
            }
        })
        return (
            <React.Fragment>
                {this.state.mainLoader && <Loader />}
                <div className="outer-cover" ref={this.myRef}>
                    {selectedImage ? <Modal body={this.renderImgModal()} /> : ""}
                    <div className="banner-strip text-center">
                        <nav className="d-flex align-items-center ">
                            <a
                                className="nav-brand"
                                style={{ cursor: "pointer" }}
                                onClick={() => (window.location.href = "/")}
                            >
                                <img src="images/main-logo.png" alt="" />
                            </a>
                            <ul className="ml-auto">
                                <li style={{ cursor: "pointer" }} className="active">
                                    <a
                                        onClick={() => (window.location.href = "/")}
                                        className=" action"
                                    >
                                        HOME
                                    </a>
                                </li>
                                <li style={{ cursor: "pointer" }}>
                                    {/* <a onClick={() => (window.location.href = "/my-meme")} className="action">MY */}
                                    <a onClick={() => history.push("/my-meme")} className="action">
                                        MY INVITATIONS
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <header className="banner-content d-flex align-items-center flex-column justify-content-center">
                            <h1 className="whitecolor">
                                MAZEL TOV! CREATE YOUR OWN FREE INVITATION!
                            </h1>
                            <p>
                                Choose one of our templates below, or create your own from scratch
                            </p>
                            <a
                                href=""
                                className="create-meme primary-action action"
                                data-toggle="modal"
                                data-target="#new-meme-modal"
                            >
                                <span>
                                    <img src="images/edit-icon.svg" alt="" />
                                </span>
                                DESIGN YOUR OWN INVITATION
                            </a>
                        </header>
                    </div>

                    <InfiniteScroll
                        dataLength={this.state.limit} //This is important field to render the next data
                        next={this.fetchMoreData}
                        hasMore={this.state.limit >= this.state.memeCount ? false : true}
                        loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                {this.state.limit >= this.state.memeCount ? (
                                    <b>Yay! You have seen it all</b>
                                ) : (
                                        <b>Loading...</b>
                                    )}
                            </p>
                        }
                    >
                        <div className="category-strip d-flex text-center align-items-center">
                            <div className="container-fluid">
                                <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        this.onCategoryChange("");
                                    }}
                                    className={`category action ${
                                        categoryId === "" ? "active" : ""
                                        }`}
                                >
                                    All
                                </a>
                                {categoryList &&
                                    categoryList.map((category, index) => {
                                        return (
                                            <a
                                                key={category.id}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    this.onCategoryChange(
                                                        category._id,
                                                        category.name
                                                    );
                                                }}
                                                className={`category action ${
                                                    categoryId === category._id ? "active" : ""
                                                    }`}
                                            >
                                                {category.name}
                                            </a>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="list-cover">
                            <div role="main" className="container">
                                <h2>
                                    {catName === "undefined" ||
                                        catName === null ||
                                        catName === "null"
                                        ? "ALL INVITATIONS"
                                        : catName.toUpperCase()}{" "}
                                    <span>
                                        {this.props.getAllMemeAction.allMemesDetails.catImages &&
                                            this.props.getAllMemeAction.allMemesDetails.catImages
                                                .length}
                                    </span>
                                </h2>
                                <ul className="tiles-wrap animated" ref="wookmark" id="wookmark1" ref={this.wookmarkRef} onLoad={this.refreshStyle}>
                                    {catImages && catImages.length ? (
                                        catImages.map((img, idx) => {
                                            return (
                                                <li key={img._id}>
                                                    <span
                                                        data-toggle="modal"
                                                        data-target="#edit-modal"
                                                    >
                                                        <img
                                                            width="300px"
                                                            src={
                                                                `${apiLink.baseUrl}` +
                                                                img.memePath[0].thumb
                                                            }
                                                            alt=""
                                                        />
                                                        <div className="image-info-cover d-flex justify-content-end">
                                                            <div className="row">
                                                                <div className="col-sm-9">
                                                                    <p>
                                                                        {img.categoryId.name.toUpperCase()}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-sm-3"
                                                                    onClick={() => {
                                                                        this.editPage(img);
                                                                    }}
                                                                >
                                                                    <span className="edit">
                                                                        &nbsp;
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </li>
                                            );
                                        })
                                    ) : (
                                            <p>No invitation found in this category!!</p>
                                        )}
                                </ul>
                            </div>
                            <div className="load-cover" style={{ display: "none" }}>
                                <a href="" className="loading">
                                    LOAD MORE ..
                                </a>
                            </div>
                        </div>
                        <div className="modal fade" id="new-meme-modal" data-backdrop="static">
                            <div className="modal-dialog modal-upload-image">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Upload Image</h4>
                                        <button
                                            type="button"
                                            // onClick={() => window.location.reload(true)}
                                            className="close img-upload-clear"
                                            data-dismiss="modal"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    <div className="modal-body modal-form">
                                        {/* <div className="upload-image-con"> */}
                                        <label
                                            style={{ cursor: "pointer" }}
                                            className="upload-image-con"
                                            for="upload-image"
                                        >
                                            <div className="upload-image-inner">
                                                <div>
                                                    <div className="image-icon">
                                                        <i class="material-icons">
                                                            add_photo_alternate
                                                        </i>
                                                        {/* <img src="images/image-icon.svg" alt=""/> */}
                                                    </div>
                                                    <label for="upload-image">
                                                        <strong>Click here</strong> to{" "}
                                                        <span className="text-before">
                                                            upload image
                                                        </span>
                                                        <span className="text-after">
                                                            change image
                                                        </span>
                                                    </label>
                                                    <input
                                                        ref="file"
                                                        type="file"
                                                        name=""
                                                        id="upload-image"
                                                        className="box__file"
                                                        data-multiple-caption=""
                                                        onChange={e => {
                                                            this._onChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="uploaded_image_con">
                                                <div className="image-for-desktop">
                                                    <div className="uploaded_image">&nbsp;</div>
                                                    <p>Desktop view</p>
                                                </div>
                                                <div className="image-for-mobile">
                                                    <div className="uploaded_image">&nbsp;</div>
                                                    <p>Mobile view</p>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            className="btn btn-default img-upload-clear"
                                            // onClick={() => window.location.reload(true)}
                                            aria-hidden="true"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <input
                                            type="button"
                                            onClick={() => {
                                                this.uploadData();
                                            }}
                                            className="btn btn-primary"
                                            value="Continue"
                                        />

                                        <input
                                            type="hidden"
                                            data-dismiss="modal"
                                            ref={this.modalClose}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfiniteScroll>

                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    getAllMemeAction: state.homeindex
});

export default withRouter(connect(mapStateToProps, { ...homeIndexAction })(homeIndex));
