import apiLink from "../api/apiLink";
import apiCall from "../api/apiCall";

const headerText = {"Access-Control-Allow-Origin": "*"};

const getAllCatagoryData = async params => {
    const paramsData = {
        apiLink: apiLink.getAllMemeCategories,
        method: "get",
        headers: {
            headerText
        }
    };
    const allCatagoryData = await apiCall.getApiCall(paramsData);
    return allCatagoryData;
};

const getAllMemesList = async params => {
    const paramsData = {
        data: params,
        apiLink: apiLink.getAllMemeData,
        method: "get",
        headers: {
            headerText
        }
    };
    // if (params) {
    //   paramsData.data = { category: params.category };
    // }
    // console.log("paramsData", paramsData);
    const allCatagoryData = await apiCall.getApiCall(paramsData);
    // console.log("allCatagoryData", allCatagoryData);
    return allCatagoryData;
};

const makePayment = async params => {
    const paramsData = {
        apiLink: apiLink.makePayment,
        method: "post",
        headers: {
            headerText
        }
    };
    if (params) {
        paramsData.data = params;
    }
    const makePaymentResult = await apiCall.postApiCall(paramsData);
    return makePaymentResult;
};

const fetchFonts = async params => {
    const paramsData = {
        // data: params,
        apiLink: apiLink.fetchFonts,
        method: "get",
        headers: {
            headerText
        }
    };
    // if (params) {
    //   paramsData.data = { category: params.category };
    // }
    // console.log("paramsData", paramsData);
    const allCatagoryData = await apiCall.getApiCall(paramsData);
    // console.log("fontResult", allCatagoryData);
    return allCatagoryData;
};

const freeDownload = async params => {
    const paramsData = {
        data: params,
        apiLink: apiLink.freeDownload,
        method: "get",
        headers: {
            headerText
        }
    };
    // if (params) {
    //   paramsData.data = { category: params.category };
    // }
    console.log("paramsData", paramsData);
    const freeDownloadDAta = await apiCall.getApiCall(paramsData);
    console.log("fontResult", freeDownloadDAta);
    return freeDownloadDAta;
};

export default {
    getAllCatagoryData,
    getAllMemesList,
    makePayment,
    fetchFonts,
    freeDownload
};
