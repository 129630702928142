import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return (
            <footer>

                <div className="social-cover d-flex align-items-center justify-content-center">
                    <div className="col-sm-3">
                        <a style={{cursor:"pointer"}} onClick={()=>{ window.location.href = '/'}}>
                            <img style={{width:"250px",maxWidth:"100%"}} src="images/footer-logo.png" alt="" />
                        </a>
                    </div>
                    <div class="col-sm-6 col-sm-6 text-right  align-items-center d-flex justify-content-end">
                          <span style={{cursor:"pointer"}} onClick={()=>{window.open("https://www.kornerstonemedia.com/", '_blank')}} class="copyRightTest"> © 2020 eSimchaVites | Powered by Kornerstone Media</span>
                       </div>
                </div>

            </footer>
        )
    }
}
export default Footer;
