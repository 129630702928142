import React, { useState, useEffect } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";

import homeIndexAction from "../../actions/homeIndexAction";
import usePrevious from "../common/usePrevious";
import history from "../config/history";
import apiLink from "../../api/apiLink";

const Stripe = props => {
    const [cardData, setCardData] = useState({
        name: "",
        email: "",
        isCompleted: false
    });
    const [paymentData, setPaymentData] = useState({
        memeId: "",
        amount: 0
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);

    const prevPaymentResult = usePrevious(props.getAllMemeAction.paymentResult);

    // useEffect(() => {
    //   setPaymentData(props.paymentData);
    //   console.log("props.paymentData", props.paymentData);
    // }, []);

    const handleSubmit = async () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?(\.\w{2,3})+)*(\.\w{2,3})+$/;
        //  let nameVaild =/^\d+$/

        setErrorMessage("");
        let error = false;
        if (!cardData.email) {
            error = true;
            setErrorMessage("Enter Email Address");
        }
        if (cardData.email && !reg.test(cardData.email)) {
            error = true;
            setErrorMessage("Enter Vaild Email Address");
        }
        if (!cardData.name) {
            error = true;
            setErrorMessage("Enter Name");
        }
        // if(cardData.name &&  !nameVaild.test(cardData.name)){
        //   error = true;
        //   setErrorMessage("Enter Vaild Name");
        // }
        if (!cardData.isCompleted) {
            error = true;
            setErrorMessage("Enter Card Details");
        }
        if (!error) {
            props.setLoader();
            let stripParams = {
                name: cardData.name
            };
            let response = await props.stripe.createToken(stripParams);
            if (response.token) {
                setIsSubmit(true);
                const params = {
                    stripeEmail: cardData.email,
                    stripeToken: response.token.id,
                    name: cardData.name,
                    amount: props.paymentData.amount
                };
                if (props.paymentData.memeId) params.memeId = props.paymentData.memeId;
                await props.makePayment(params);
            } else {
                props.setLoader(false);
                setErrorMessage(
                    (response.error && response.error.message) ||
                        "Something went wrong, Please try again!..."
                );
            }
        }
    };

    useEffect(() => {
        if (
            isSubmit &&
            prevPaymentResult !== props.getAllMemeAction.paymentResult &&
            props.getAllMemeAction.paymentResult.success
        ) {
            setIsSubmit(false);
            // window.location.href = `${apiLink.baseUrl}${props.getAllMemeAction.paymentResult.data.catImages[0].memePath[0].original}`;
            // props.downloadImage();
            let imageData = Array.isArray(props.getAllMemeAction.paymentResult.data.catImages)
                ? props.getAllMemeAction.paymentResult.data.catImages[0].path[0].original
                : "";
            imageData = imageData ? `${apiLink.baseUrl}${imageData}` : "";

            props.hidePayment(imageData, true);
            // history.push("/");
        } else if (
            isSubmit &&
            prevPaymentResult !== props.getAllMemeAction.paymentResult &&
            !props.getAllMemeAction.paymentResult.success
        ) {
            setIsSubmit(false);
            props.hidePayment("", false);
        }
    }, [prevPaymentResult, props.getAllMemeAction.paymentResult]);

    const handleChange = stripElement => {
        setCardData({ ...cardData, isCompleted: false });
        if (stripElement.complete) {
            setCardData({ ...cardData, isCompleted: true });
        }
    };

    const handlerChange = e => {
        setErrorMessage("");
        switch (e.target.name) {
            case "cardName":
                setCardData({ ...cardData, name: e.target.value });
                break;
            case "email":
                setCardData({ ...cardData, email: e.target.value });
                break;
            default:
                break;
        }
    };
    return (
        <Modal show={props.showPayment} onHide={props.hidePayment}>
            <Modal.Header>
                <Modal.Title>Make Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container justify-content-between">
                    <label>Card details</label>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <CardElement onChange={handleChange} />
                    <div className="d-flex">
                        <input
                            className="mr-2 form-control"
                            placeholder="Name"
                            name="cardName"
                            onChange={e => handlerChange(e)}
                        />
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                            onChange={e => handlerChange(e)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="pay-button" onClick={() => handleSubmit()}>
                    Pay
                </button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        getAllMemeAction: state.homeindex
    };
};

export default connect(mapStateToProps, { ...homeIndexAction })(injectStripe(Stripe));
