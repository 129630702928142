import constants from "../../constants/constants";
const {
  GET_ALL_DATA_MEME_REQUEST,
  GET_ALL_DATA_MEME_SUCCESS,
  GET_ALL_DATA_MEME_FAILURE,
  GET_ALL_MEMES_LIST_REQUEST,
  GET_ALL_MEMES_LIST_SUCCESS,
  GET_ALL_MEMES_LIST_FAILURE,
  MAKE_PAYMENT_REQUEST,
  MAKE_PAYMENT_SUCCESS,
  MAKE_PAYMENT_FAILURE,
  FETCH_FONT_FAILURE,
  FETCH_FONT_REQUEST,
  FETCH_FONT_SUCCESS,
  FREE_DOWNLOAD_REQUEST,
FREE_DOWNLOAD_SUCCESS,
FREE_DOWNLOAD_FAILURE,
} = constants;

const initialState = {
  allMemeDetails: {},
  allMemesDetails: {},
  paymentResult: {},
  fetchFontDetails:{},
  freeDownloadDetails:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DATA_MEME_REQUEST:
      return {
        ...state
      };
    case GET_ALL_DATA_MEME_SUCCESS:
      return {
        ...state,
        allMemeDetails: { success: true, ...action.categoryData.data }
      };
    case GET_ALL_DATA_MEME_FAILURE:
      return {
        ...state,
        allMemeDetails: { success: false, ...action.error }
      };
    case GET_ALL_MEMES_LIST_REQUEST:
      return {
        ...state
      };
    case GET_ALL_MEMES_LIST_SUCCESS:
      return {
        ...state,
        allMemesDetails: { success: true, ...action.memeAllData.data }
      };
    case GET_ALL_MEMES_LIST_FAILURE:
      return {
        ...state,
        allMemesDetails: { success: false, ...action.error }
      };
    case MAKE_PAYMENT_REQUEST:
      return {
        ...state
      };
    case MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentResult: { success: true, ...action.paymentSuccess }
      };
    case MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentResult: { success: false, ...action.error }
      };



      case FETCH_FONT_REQUEST:
        return {
            ...state
        };
    case FETCH_FONT_SUCCESS:
        return {
            ...state,
            fetchFontDetails: { success: true, ...action.fetchFontAction }
        };
    case FETCH_FONT_FAILURE:
        return {
            ...state,
            fetchFontDetails: { success: false, ...action.error }
        };

        case FREE_DOWNLOAD_REQUEST:
          return {
              ...state
          };
      case FREE_DOWNLOAD_SUCCESS:
          return {
              ...state,
              freeDownloadDetails: { success: true, ...action.freeDownloadAction }
          };
      case FREE_DOWNLOAD_FAILURE:
          return {
              ...state,
              freeDownloadDetails: { success: false, ...action.error }
          };

    default:
      return state;
  }
};
