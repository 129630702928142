import React, { Component } from 'react'

class allInvitations extends Component {
    render() {
        return (
            <>

            </>
        )
    }
}
export default allInvitations;
