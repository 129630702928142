import React from "react";
import "./App.css";
import { Router, Switch } from "react-router-dom";
import history from "./components/config/history";
import PublicRoute from "./components/common/PublicRoute";
import homeIndex from "./components/home/homeIndex";
import CommonRoute from "./components/common/CommonRoute";
import allInvitations from "./components/home/allEvents/allInvitations";
import vort from "./components/home/allEvents/vort";
import EditorView from './components/common/EditorView.jsx';
import MymemeNew from "./components/common/Mymeme";
import Payment from "./components/common/Payment";
import Download from "./components/common/Download";
function App() {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path="/" exact component={homeIndex} />
        <PublicRoute path="/create-meme" exact component={EditorView} />
        <PublicRoute path="/my-meme" exact component={MymemeNew} />
        <PublicRoute path="/download" exact component={Download} />
      </Switch>
    </Router>
  );
}

export default App;

