import React, { Component } from "react";
import Footer from "../home/Footer";
import history from "../config/history";
import Loader from "../home/Loader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MyMemeFooter from "../home/allEvents/MyMemeFooter";
import apiLink from "../../api/apiLink";

class Mymeme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            savedMemes: [],
            loader: false,
            Delete: false,
            showModal: true
        };
    }
    mapping = () => {
        this.state.memedata.map((item, index) => {
            // console.log("item", item.data.meme);
        });
    };
    componentDidMount = async () => {
        await this.setState({ loader: true });
        let Mymemes = JSON.parse(localStorage.getItem("savedMemes"));
        // console.log("Mymemes", Mymemes);
        if (Mymemes !== undefined && Mymemes !== null)
            await this.setState({
                savedMemes: Mymemes
            });
        await setTimeout(() => {
            this.setState({ loader: false });
        }, 5000);
    };
    editPage = img => {
        localStorage.setItem("selectedImage", JSON.stringify(img.image));
        history.push({
            pathname: "/create-meme",
            state: {
                base64: img.image,
                imgProperty: img.textArray,
                edit: "edit",
                img: img.img,
                price: img.price || 4.99
            }
        });
    };
    delete = () => {
        let savedMemes = [...this.state.savedMemes];
        let selectedMeme = this.state.selectedMeme;
        let newArray = savedMemes.filter((meme, index) => {
            return meme.id !== selectedMeme
        })
        this.setState({
            savedMemes: newArray
        })
        localStorage.setItem("savedMemes", JSON.stringify(newArray));
        this.handleClose();
    };
    handleClose = e => {
        this.setState({
            showModal: false,
            Delete: false
        });
    };

    render() {
        return (
            <div class="outer-cover">
                {this.state.loader && <Loader />}
                <div class="banner-strip text-center banner-strip-inner">
                    <nav class="d-flex align-items-center ">
                        <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                window.location.href = "/";
                            }}
                            class="nav-brand"
                        >
                            <img src="images/main-logo.png" alt="" />
                        </a>
                        <ul class="ml-auto">
                            <li>
                                <a onClick={() => (window.location.href = "/")} class=" action">
                                    HOME
                                </a>
                            </li>
                            <li class="active">
                                <a
                                    onClick={() => (window.location.href = "/my-meme")}
                                    class=" action"
                                    class="active"
                                >
                                    MY INVITATIONS
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div role="main" class="page-outer my-invitations-outer">
                    <div class="list-cover">
                        <div role="main" class="container">
                            <h2>
                                MY INVITATIONS{" "}
                                <span>{this.state.memedata && this.state.memedata.length}</span>
                            </h2>
                            <ul class="tiles-wrap animated" id="wookmark1">
                                {
                                    this.state.savedMemes !== null || this.state.savedMemes.lenght !== 0 ? this.state.savedMemes.map((meme) => {
                                        {/* console.log("imgLiasassting", meme); */ }
                                        return (
                                            <li>
                                                <span
                                                    href=""
                                                    data-toggle="modal"
                                                    data-target="#edit-modal"
                                                >
                                                    <img
                                                        src={meme.thumb}
                                                        width="300"
                                                        height="auto"
                                                    />
                                                    <div className="image-info-cover d-flex justify-content-end">
                                                        <div className="row">
                                                            <div className="col-sm-9">
                                                                {/* <p>{img.Mymemes.img.categoryId.name}</p> */}
                                                                {/* <p>{img.Mymemes.img.memePath[0].title}</p> */}
                                                                {/* <label>Angry Meme</label> */}
                                                            </div>

                                                            <div
                                                                className="col-sm-3 edit-btn-cover"
                                                            // style={{marginBottom:"15%"}}
                                                            // onClick={() => this.selectImage(img.Mymemes.img)}
                                                            // onClick={() => {
                                                            //   this.editPage(img);
                                                            // }}
                                                            >
                                                                <a
                                                                    class="edit-btn"
                                                                    onClick={() => {
                                                                        this.editPage(meme);
                                                                    }}
                                                                >
                                                                    <i class="material-icons">
                                                                        edit
                                                                    </i>
                                                                </a>
                                                                <a
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            Delete: true,
                                                                            selectedMeme: meme.id
                                                                        })
                                                                    }
                                                                    class="delete-btn"
                                                                >
                                                                    <i class="material-icons">
                                                                        delete
                                                                    </i>
                                                                </a>

                                                                {/* <span className="edit"></span> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </span>
                                            </li>
                                        );
                                    }) : <p>No Saved Memes</p>}
                            </ul>
                            {
                                this.state.savedMemes.length === 0 || this.state.savedMemes === null ? <p>No Saved Memes</p> : ""
                            }
                        </div>
                    </div>
                </div>
                <Modal show={this.state.Delete} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "auto" }}>Delete Invitation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: "center" }}>
                        Are you sure to delete this Invitation?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={this.delete}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <MyMemeFooter />
            </div>
        );
    }
}

export default Mymeme;
