import constants from "../constants/constants";
import homeindexServices from "../services/homeindexService";
const {
  GET_ALL_DATA_MEME_REQUEST,
  GET_ALL_DATA_MEME_SUCCESS,
  GET_ALL_DATA_MEME_FAILURE,
  GET_ALL_MEMES_LIST_REQUEST,
  GET_ALL_MEMES_LIST_SUCCESS,
  GET_ALL_MEMES_LIST_FAILURE,
  MAKE_PAYMENT_REQUEST,
  MAKE_PAYMENT_SUCCESS,
  MAKE_PAYMENT_FAILURE,
  FETCH_FONT_REQUEST,
FETCH_FONT_SUCCESS,
FETCH_FONT_FAILURE,
FREE_DOWNLOAD_REQUEST,
FREE_DOWNLOAD_SUCCESS,
FREE_DOWNLOAD_FAILURE,
} = constants;

const getAllCatagoryData = () => {
  return async dispatch => {
    try {
      dispatch({ type: GET_ALL_DATA_MEME_REQUEST });
      const res = await homeindexServices.getAllCatagoryData();
      if (res.status === 200) {
        const categoryData = res.data;
        if (categoryData.success) {
          dispatch({ type: GET_ALL_DATA_MEME_SUCCESS, categoryData });
        } else {
          dispatch({ type: GET_ALL_DATA_MEME_FAILURE, error: categoryData });
        }
      } else {
        dispatch({ type: GET_ALL_DATA_MEME_FAILURE, error: res.response.data });
      }
    } catch (e) {
      dispatch({
        type: GET_ALL_DATA_MEME_FAILURE,
        error: e.response && e.response.data
      });
    }
  };
};

const getAllMemesList = params => {
  return async dispatch => {
    try {
      dispatch({ type: GET_ALL_MEMES_LIST_REQUEST });
      const res = await homeindexServices.getAllMemesList(params);
      if (res.status === 200) {
        const memeAllData = res.data;
        if (memeAllData.success) {
          dispatch({ type: GET_ALL_MEMES_LIST_SUCCESS, memeAllData });
        } else {
          dispatch({ type: GET_ALL_MEMES_LIST_FAILURE, error: memeAllData });
        }
      } else {
        dispatch({
          type: GET_ALL_MEMES_LIST_FAILURE,
          error: res.response.data
        });
      }
    } catch (e) {
      dispatch({
        type: GET_ALL_MEMES_LIST_FAILURE,
        error: e.response && e.response.data
      });
    }
  };
};

const makePayment = params => {
  return async dispatch => {
    try {
      dispatch({ type: MAKE_PAYMENT_REQUEST });
      const res = await homeindexServices.makePayment(params);
      if (res.status === 200) {
        const paymentSuccess = res.data;
        if (paymentSuccess.success) {
          // alert("Payment has been successfully completed")
          dispatch({ type: MAKE_PAYMENT_SUCCESS, paymentSuccess });
        } else {
          // alert("Payment has been failed")
          dispatch({ type: MAKE_PAYMENT_FAILURE, error: res.response.data });
        }
      } else {
        // alert("Payment has been failed")
        dispatch({ type: MAKE_PAYMENT_FAILURE, error: res.response.data });
      }
    } catch (e) {
      dispatch({
        type: MAKE_PAYMENT_FAILURE,
        error: e.response && e.response.data
      });
    }
  };
};
const fetchFonts = (params) => {

  const request = fetchFontAction => ({
      type: FETCH_FONT_REQUEST,
      fetchFontAction
  });
  const success = fetchFontAction => ({
      type: FETCH_FONT_SUCCESS,
      fetchFontAction
  });
  const failure = error => ({
      type: FETCH_FONT_FAILURE,
      error
  });

  return async dispatch => {
      try {
          dispatch(request());
          // console.log('Publishparams', params)
          const res = await homeindexServices.fetchFonts(params)
          // console.log('Publishres', res)
          if (res.status === 200) {
              const fetchFontsData = res.data;
              if (fetchFontsData.success) {
                  dispatch(success(fetchFontsData));
                  // alert("Data Published")
                  // history.push('/memes');
                  // window.location.href = '/memes'
              } else {
                  dispatch(failure(fetchFontsData));
              }
          } else {
              dispatch(failure(res.response.data));
          }
      } catch (e) {
          dispatch(failure(e.response && e.response.data));
      }
  }
}

const freeDownload = (params) => {

  const request = freeDownloadAction => ({
      type: FREE_DOWNLOAD_REQUEST,
      freeDownloadAction
  });
  const success = freeDownloadAction => ({
      type: FREE_DOWNLOAD_SUCCESS,
      freeDownloadAction
  });
  const failure = error => ({
      type: FREE_DOWNLOAD_FAILURE,
      error
  });

  return async dispatch => {
      try {
          dispatch(request());
          // console.log('Publishparams', params)
          const res = await homeindexServices.freeDownload(params)
          // console.log('Publishres', res)
          if (res.status === 200) {
              const freeDownloadData = res.data;
              if (freeDownloadData.success) {
                  dispatch(success(freeDownloadData));
                  // alert("Data Published")
                  // history.push('/memes');
                  // window.location.href = '/memes'
              } else {
                  dispatch(failure(freeDownloadData));
              }
          } else {
              dispatch(failure(res.response.data));
          }
      } catch (e) {
          dispatch(failure(e.response && e.response.data));
      }
  }
}

export default {
  getAllCatagoryData,
  getAllMemesList,
  makePayment,
  fetchFonts,
  freeDownload
};
