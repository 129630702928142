import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import Stripe from "./Stripe";
import StripeScriptLoader from "react-stripe-script-loader";
const apiKeyTest = "pk_test_sU5rwMi96OllTvOvNC018trx005W8VzMkf";
const apiKeyLive = "pk_live_4mNQlHy1GUMmHBXbpDb73XLA";
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <StripeProvider apiKey={apiKeyLive}>
        <Elements>
          <Stripe {...this.props} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default Payment;
