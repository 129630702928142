import React from 'react'

const overlay = {
    position: "fixed",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.45)",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    left: 0,
    top: 0
}

function Loader() {
    return (
        <div style={overlay}>
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader