import React, { Component } from 'react';
import Footer from "../home/Footer";
import Loader from "../home/Loader";
import history from "../config/history";
import EditableElement from './EditableElement';
import { connect } from 'react-redux';
import homeIndexAction from '../../actions/homeIndexAction';
import { withRouter } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Payment from './Payment';
import { createGlobalStyle } from "styled-components";
import apiLink from '../../api/apiLink';
import htmlTocanvas from 'html2canvas';
import { SketchPicker } from 'react-color';
import { Spinner } from 'react-bootstrap';

class EditorView extends Component {
    constructor(props) {
        super(props);
        this.image = "";
        this.state = {
            fontList: [],
            imageBase66: '',
            img: '',
            selectedText: null,
            wattermark: true,
            stageHeight: 0,
            stageWidth: 0,
            textList: [],
            price: 4.99,
            paymentData: {
                memeId: "",
                amount: 0
            },
            paymentSucess: false,
            paymentstatus: false,
            loader: true,
            mobile: false,
            contentLoader: true
        }
        this.artboardRef = React.createRef();
        this.imageRef = React.createRef();
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await this.setState({
            loader: true,
            contentLoader: true
        });
        await this.props.fetchFonts();
        await this.setState({
            fontList:
                this.props.fetchFontAction &&
                this.props.fetchFontAction.fetchFontDetails &&
                this.props.fetchFontAction.fetchFontDetails.data &&
                this.props.fetchFontAction.fetchFontDetails.data.list
        });
        // console.log(history.location.state, "history State")
        if (history.location.state === undefined) {
            window.location.href = "/";
        } else {
            await this.setState({
                textList: history.location.state.imgProperty === undefined ? [] : history.location.state.imgProperty,
                imageBase66: history.location.state.base64,
                img: history.location.state.img,
                price: history.location.state.price
            });
            let image = JSON.parse(localStorage.getItem("selectedImage"));
            await this.setState({
                selectedImage: image
            });
        }
        window.addEventListener('resize', this.resizeListerner);

        // await this.refreshStyle();
        // console.log(document.getElementById("editedElement").offsetHeight,document.getElementById("editedElement").offsetWidth)
        await this.setState({
            stageHeight: document.getElementById("editedElement").offsetHeight,
            stageWidth: document.getElementById("editedElement").offsetWidth
        })
        await this.artboardRef.current.click();
        setTimeout(async () => {
            await this.setState({ contentLoader: false })
        }, 500);

    }
    resizeListerner = async () => {
        if (this.state.stageWidth !== this.artboardRef.current.offsetWidth || this.state.stageHeight !== this.artboardRef.current.offsetHeight) {
            await this.setState({
                stageHeight: document.getElementById("editedElement").offsetHeight,
                stageWidth: document.getElementById("editedElement").offsetWidth
            })
            await this.artboardRef.current.click()
        }
    }
    async componentDidUpdate() {
        if (this.state.download && this.state.paymentSucess && this.state.paymentstatus) {
            await this.downloadImage();
        }
        this.resizeListerner()
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeListerner);
    }
    addText = () => {
        this.setState((prevState) => {
            let newState = [...prevState.textList];
            newState.push({ fontSize: 24, value: '<div>Your Text Here</div>', fontFamily: 'roboto', color: '#000000', fontStyle: '', align: 'left', lineHeight: 1, bold: false, italic: false, top: 0, left: 0, vCenter: false, hCenter: false });
            this.setState({
                textList: newState
            })
        })
    }
    closeDownload = async () => {
        let imageDiv = document.getElementById("image-showcase-inner")
        imageDiv.innerHTML = "";

        await this.setState({
            imgBase64: ''
        })
    };
    handleClick = async (e, i) => {
        // console.log(e, i)
        if (e.stopPropagation) {
            e.stopPropagation();   // W3C model
        } else {
            e.cancelBubble = true; // IE model
        }
        await this.setState({ selectedText: i });
    };

    handleChange = async (e) => {
        let newValue = e.target.value
        let name = e.target.name;
        let checked = e.target.checked;
        let selectedText = this.state.selectedText;
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                if (name === "lineHeight")
                    newArray[prevState.selectedText][name] = newValue;
                else if (name === "bold" || name === "italic") {
                    newArray[prevState.selectedText][name] = checked;
                }
                else
                    newArray[prevState.selectedText][name] = newValue;
                this.setState({
                    textList: newArray
                })
            })
        }
        this.artboardRef.current.click();
        this.setState({ selectedText })
    }
    handleColorChange = e => {
        let value = e.target.value
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                newArray[prevState.selectedText].color = value;
                this.setState({
                    textList: newArray
                })
            })
        }
    }
    handleCheckBox = (e) => {
        let newValue = e.target.value
        let name = e.target.name;
        let checked = e.target.checked;
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                // console.log(newArray[prevState.selectedText][name])
                if (checked)
                    newArray[prevState.selectedText][name] = newValue;
                else
                    newArray[prevState.selectedText][name] = "";
                this.setState({
                    textList: newArray
                })
            })
        }
    }
    handleBoldItalicChange = (e) => {
        let newValue = e.target.value
        let name = e.target.name;
        let checked = e.target.checked;
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                newArray[prevState.selectedText][name] = checked;
                this.setState({
                    textList: newArray
                })
            })
        }
    }
    handleUserColorChange = (color) => {
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                newArray[prevState.selectedText].color = color.hex;
                this.setState({
                    textList: newArray
                })
            })
        }
    }
    processImage = async () => {
        window.scrollTo(0, 0);
        document.getElementById("image-showcase-inner").innerHTML = "";
        if (this.state.img && this.state.img._id) {
            this.setState({
                paymentData: {
                    memeId: this.state.img._id,
                    amount: this.state.img.price
                }
            });
        } else {
            this.setState({
                paymentData: {
                    memeId: "",
                    amount: this.state.price
                }
            });
        }
        await this.setState({ loader: true, selectedText: null });
        var imageHtml = document.getElementById("artboard");

        let self = this;
        const scale = 750 / imageHtml.offsetWidth;
        htmlTocanvas(imageHtml, {
            quality: 1.0,
            useCORS: true
        }).then(canvas => {
            setTimeout(async () => {
                let png = canvas.toDataURL('image')
                // console.log("Png Image is", png)
                let image = new Image();
                image.src = png;
                document.getElementById("image-showcase-inner").appendChild(image)
                await self.setState({ thumb: png, imgBase64: png, loader: false, });
                // console.log("State Set")
            }, 2000);
        })
    };
    editText = value => {
        if (this.state.selectedText !== null) {
            let array = [...this.state.textList];
            array[this.state.selectedText].value = value;
            // console.log(array)
            this.setState({
                textList: array
            })
        }
    }
    handleDragEnd = (x, y) => {
        let array = [...this.state.textList];
        let selectedText = this.state.selectedText;
        if (selectedText !== undefined) {
            array[selectedText].top = y;
            array[selectedText].left = x;
            array[selectedText].vCenter = false;
            array[selectedText].hCenter = false;
            this.setState({
                textList: array
            })
        }

    }
    SaveMymemes = async () => {
        let savedMemeArray = JSON.parse(localStorage.getItem("savedMemes"))
        if (savedMemeArray === undefined || savedMemeArray === null)
            savedMemeArray = [];
        if (savedMemeArray.length > 2) {
            this.setState({
                update: false,
                MemeNotSaved: true
            })
            return;
        }
        // await this.processImage();
        let meme = {}
        meme.textArray = this.state.textList;
        meme.image = this.state.imageBase66;
        meme.thumb = `${apiLink.baseUrl}${this.state.img.memePath[0].original}`;
        meme.id = "meme" + Math.floor((Math.random() * 90000)) + 10000;
        meme.price = this.state.price;
        meme.img=this.state.img;
        meme.path=this.state.path;
        // console.log("Meme", meme)
        savedMemeArray.push(meme)
        // console.log("Array", savedMemeArray)
        localStorage.setItem("savedMemes", JSON.stringify(savedMemeArray));
        // localStorage.setItem()
        this.setState({
            update: false,
            MemeSaved: true
        })
    }
    downloadImage = async () => {
        console.log("Download")
        window.scrollTo(0, 0);
        // console.log("dwld");
        const thisObj = this;
        setTimeout(() => {
            var imageHtml = document.getElementById("artboard");
            const scale = 750 / imageHtml.offsetWidth;
            htmlTocanvas(imageHtml, {
                quality: 1.0,
                useCORS: true
            }).then(canvas => {

                let png = canvas.toDataURL('image')
                let image = new Image();
                image.src = png;
                if (!this.state.mobile) {

                    var element = document.createElement("a");
                    element.href = png;
                    element.download = "image";
                    element.click();
                    thisObj.setState({ thumb: png, imgBase64: png, loader: false });
                }
                else {
                    this.props.history.push({
                        pathname: "/download",
                        state: {
                            dataUrl: png,
                        }
                    });
                }
                // console.log("Png Image is", png)
                thisObj.setState({
                    loader: false,
                    download: false,
                    wattermark: true,
                    imageBase66: thisObj.state.watermarkImage
                });
                // await thisObj.setState({ thumb: png, imgBase64: png, loader: false, });
                // console.log("State Set")
            })
        }, 500);
        await this.setState({
            download: false
        });
    };
    // blob2file = blobData => {
    //     const fd = new FormData();
    //     fd.set("a", blobData);
    //     return fd.get("a");
    // };
    // convertBase64ToFile = image => {
    //     console.log(image)
    //     let imageFile = image.split(",");
    //     const byteString = atob(imageFile[1]);
    //     const ab = new ArrayBuffer(byteString.length);
    //     const ia = new Uint8Array(ab);
    //     for (let i = 0; i < byteString.length; i += 1) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }
    //     // data:image/png
    //     let imageFileType = imageFile[0].split(";")[0].split(":");
    //     // console.log("imageFileType", imageFileType);
    //     const newBlob = new Blob([ab], {
    //         type: imageFileType.length > 1 ? imageFileType[1] : "image/jpeg"
    //     });
    //     // console.log("newBlob", newBlob);
    //     return newBlob;
    // };
    deleteText = () => {
        let array = [...this.state.textList];
        let selectedText = this.state.selectedText;
        if (selectedText !== null && array.length > 0) {
            let newArray = array.filter((array, index) => {
                // console.log(index, selectedText)
                return index !== selectedText
            })
            // console.log("Array", newArray);
            this.setState({
                textList: newArray,
                selectedText: null
            })
        }
    }
    handleClose = e => {
        this.setState({
            showModal: false,
            update: false,
            paymentSucess: false,
            wattermark: true
        });
    };
    imageLoad = async () => {
        await this.resizeListerner()
        await this.setState({
            loader: false,
            contentLoader: false
        })
        this.artboardRef.current.click()
    }
    align = position => {
        let array = [...this.state.textList];
        let selectedText = this.state.selectedText;

        if (selectedText !== null) {
            if (position === "vCenter")
                array[selectedText]['top'] = 50;
            else
                array[selectedText]['left'] = 50;
            array[selectedText][position] = true;
            this.setState({ textList: array })
        }
    }
    makePayment = async () => {
        if (this.state.paymentData.amount <= 0) {

            let paramsData = {
                memeId: this.state.paymentData.memeId
            };

            await this.props.freeDownload(paramsData);

            await this.setState({
                imageDataOriginal: this.props.freeDownloadAction.freeDownloadDetails.data.catImages
                    .path[0].original
            });
            let imageData = this.state.imageDataOriginal;
            imageData = imageData ? `${apiLink.baseUrl}${imageData}` : "";

            await this.setState({
                zeroDownload: true
                // paymentSucess:false
            });
            this.hidePayment(imageData, true);
        } else {
            this.setState({ showPayment: true });
        }
    };
    setLoader = () => {
        this.setState({ loader: !this.state.loader });
    };
    hidePayment = (originalImage, sucess) => {
        // console.log("originalImage", originalImage);
        const { selectedImage, imageBase66 } = this.state;
        let imageBase66temp = imageBase66;
        this.setState({
            watermarkImage: imageBase66temp,
            imageBase66: originalImage ? originalImage : imageBase66temp,
            showPayment: false,
            download: true,
            loader: false,
            wattermark: false,
            paymentSucess: true,
            paymentstatus: sucess
        });
        // setTimeout(async () => {}, 500);
    };
    render() {
        const { fontList } = this.state;
        const GlobalStyle = createGlobalStyle`${fontList && fontList.map((item, index) => `@font-face {
            font-family: '${item.name}';
            src: url(${apiLink.baseUrl}${item.path[0].original});
          }`)}`;
        var linkStyle;
        if (this.state.hover) {
            // console.log("object", this.state.hover);
            // alert(1)
            linkStyle = {
                backgroundColor: "#800080",
                color: "#ffffff",
                border: 0,
                fontSize: "18px",
                fontWeight: "350"
            };
        } else {
            // alert(2)
            // console.log("object2", this.state.hover);
            linkStyle = {
                color: "#000000",
                backgroundColor: "#800080",
                border: 0,
                fontSize: "18px",
                fontWeight: "350"
            };
        }
        let { imageBase66, textList, selectedText, stageHeight, stageWidth, showColorPalette } = this.state;
        let selectedFontSize = 16;
        let selectedFont = 'roboto';
        let selectedColor = '#FFF';
        let selectedTextUnderLine = "";
        let selectedLineHeight = "1";
        let selectedFontBold = false;
        let selectedFontItalic = false;
        let selectedTextAlignment = "left";

        if (textList.length > 0 && selectedText !== null) {
            selectedFontSize = textList[selectedText].fontSize;
            selectedFont = textList[selectedText].fontFamily;
            selectedColor = textList[selectedText].color;
            selectedTextUnderLine = textList[selectedText].textDecoration;
            selectedLineHeight = textList[selectedText].lineHeight;
            selectedFontBold = textList[selectedText].bold;
            selectedFontItalic = textList[selectedText].italic;
            selectedTextAlignment = textList[selectedText].align;
        }
        return (
            <>
                <GlobalStyle />
                {this.state.loader && <Loader />}
                <div className="outer-cover">
                    <div className="banner-strip text-center banner-strip-inner">
                        <nav className="d-flex align-items-center ">
                            <a
                                href
                                style={{ cursor: "pointer" }}
                                onClick={() => (window.location.href = "/")}
                                className="nav-brand"
                            >
                                <img src="images/main-logo.png" alt="" />
                            </a>
                            <ul class="ml-auto">
                                <li>
                                    <a
                                        href
                                        style={{ cursor: "pointer" }}
                                        onClick={() => (window.location.href = "/")}
                                        class=" action"
                                    >
                                        HOME
                                    </a>
                                </li>
                                <li class="active">
                                    <a
                                        href
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push("/my-meme")}
                                        class=" action"
                                    >
                                        MY INVITATIONS
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <span id="editorContainer1" ref="editorContainer">
                            &nbsp;
                        </span>
                    </div>

                    <div role="main" className="editor-outer">
                        <div id="editor-con" className="editor-con">
                            <div id="art-board-con"
                                className="artboard-con"
                                onClick={() => this.setState({ selectedText: null, showColorPalette: false })}>
                                <div
                                    className="artboard"
                                    id="artboard"
                                    ref={this.imageRef}
                                    style={{ zIndex: this.state.contentLoader === true ? -1 : 0, lineHeight: null }}
                                >
                                    <img id="bgimage" src={imageBase66} onLoad={this.imageLoad} alt="" />
                                    <div
                                        id="editedElement"
                                        className="parent align"
                                        ref={this.artboardRef}
                                    >
                                        {
                                            stageWidth !== 0 && stageHeight !== 0 && textList.map((text, idx) => {
                                                return (
                                                    <EditableElement artboardRef={this.artboardRef} text={text} index={idx} handleClick={(e) => this.handleClick(e, idx)} editText={this.editText} dragEnd={this.handleDragEnd} selected={selectedText} stageHeight={stageHeight} stageWidth={stageWidth} />
                                                )
                                            })
                                        }

                                        {this.state.wattermark && (
                                            <div class="wattermark">
                                                <img src="images/waterMark-3.png"></img>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="download-button-floating">
                                <button
                                    className="btn pmd-btn-fab btn-danger pmd-ripple-effect pmd-btn-raised mt-0"
                                    type="button"
                                    style={{ marginRight: 5 }}
                                    onClick={() => {
                                        this.setState({
                                            update: true
                                        });
                                    }}
                                    data-toggle="modal">
                                    <span>Save</span>
                                </button>
                                <button
                                    className="btn pmd-btn-fab btn-danger pmd-ripple-effect pmd-btn-raised mt-0"
                                    type="button"
                                    onClick={() => { this.setState({ mobile: true }); this.processImage() }}
                                    data-toggle="modal"
                                    data-target="#download-meme-modal"
                                >
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                </button>
                            </div>
                            <div className="tools-con">
                                <div className="tools-head">Output Format</div>
                                <div className="tools-block">
                                    <div className="btn-group-v">
                                        <button className="btn-tools btn-fill" onClick={this.addText}>
                                            <i className="material-icons">text_fields</i>
                                            Add Text
                                        </button>

                                    </div>
                                </div>
                                <div className="tools-block tools-block-editor">
                                    <div className="tools-block-title">Font</div>
                                    <div className="btn-group-v">
                                        <select className="form-control" name="fontFamily" value={selectedFont} onChange={(e) => this.handleChange(e)} >
                                            {this.state.fontList &&
                                                this.state.fontList.map((item, index) => {
                                                    return (
                                                        <option
                                                            value={item.name}
                                                            style={{ fontFamily: item.name }}
                                                            data-font={item.name.toLowerCase()}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}

                                            <option
                                                value="roboto"
                                                style={{ fontFamily: "roboto" }}
                                                data-font="roboto"
                                            >
                                                Roboto
                                            </option>
                                            <option
                                                value="lobster, cursive"
                                                style={{ fontFamily: "lobster, cursive" }}
                                                data-font="Lobster"
                                            >
                                                Lobster
                                            </option>
                                            <option
                                                value="poppins, sans-serif"
                                                style={{ fontFamily: "poppins, sans-serif" }}
                                                data-font="Poppins"
                                            >
                                                Poppins
                                            </option>
                                            <option
                                                value="sen, sans-serif"
                                                style={{ fontFamily: "sen, sans-serif" }}
                                                data-font="Sen"
                                            >
                                                Sen
                                            </option>
                                            <option
                                                value="inter, sans-serif"
                                                style={{ fontFamily: "inter, sans-serif" }}
                                                data-font="Inter"
                                            >
                                                Inter
                                            </option>
                                            <option
                                                value="open Sans, sans-serif"
                                                style={{ fontFamily: "open Sans, sans-serif" }}
                                                data-font="Open+Sans"
                                            >
                                                Open Sans
                                            </option>
                                            <option
                                                value="bellota, cursive"
                                                style={{ fontFamily: "bellota, cursive" }}
                                                data-font="Bellota"
                                            >
                                                Bellota
                                            </option>
                                            <option
                                                value="montserrat, sans-serif"
                                                style={{ fontFamily: "montserrat, sans-serif" }}
                                                data-font="Montserrat"
                                            >
                                                Montserrat
                                            </option>
                                            <option
                                                value="oswald, sans-serif"
                                                style={{ fontFamily: "oswald, sans-serif" }}
                                                data-font="Oswald"
                                            >
                                                Oswald
                                            </option>
                                            <option
                                                value="viaoda libre, cursive"
                                                style={{ fontFamily: "viaoda libre, cursive" }}
                                                data-font="Viaoda+Libre"
                                            >
                                                Viaoda Libre
                                            </option>
                                            {/* <option value="'Baloo Chettan 2', cursive" style={{ fontFamily: "Baloo Chettan 2, cursive" }} data-font="Baloo+Chettan+2">Baloo Chettan 2</option> */}
                                            <option
                                                value="merriweather, serif"
                                                style={{ fontFamily: "merriweather, serif" }}
                                                data-font="Merriweather"
                                            >
                                                Merriweather
                                            </option>
                                            <option
                                                value="playfair display, serif"
                                                style={{ fontFamily: "playfair display, serif" }}
                                                data-font="Playfair+Display"
                                            >
                                                Playfair Display
                                            </option>
                                            <option
                                                value="open sans condensed, sans-serif"
                                                style={{
                                                    fontFamily: "open sans condensed, sans-serif"
                                                }}
                                                data-font="Open+Sans+Condensed"
                                            >
                                                Open Sans Condensed
                                            </option>
                                            <option
                                                value="nunito, sans-serif"
                                                style={{ fontFamily: "nunito, sans-serif" }}
                                                data-font="Nunito"
                                            >
                                                Nunito
                                            </option>
                                            <option
                                                value="titillium web, sans-serif"
                                                style={{ fontFamily: "titillium web, sans-serif" }}
                                                data-font="Titillium+Web"
                                            >
                                                Titillium Web
                                            </option>
                                            <option
                                                value="quicksand, sans-serif"
                                                style={{ fontFamily: "quicksand, sans-serif" }}
                                                data-font="Quicksand"
                                            >
                                                Quicksand
                                            </option>
                                            <option
                                                value="josefin sans, sans-serif"
                                                style={{ fontFamily: "josefin sans, sans-serif" }}
                                                data-font="Josefin+Sans"
                                            >
                                                Josefin Sans
                                            </option>
                                            <option
                                                value="indie flower, cursive"
                                                style={{ fontFamily: "indie flower, cursive" }}
                                                data-font="Indie+Flower"
                                            >
                                                Indie Flower
                                            </option>
                                            {/* <option value="'Dancing Script', cursive" style={{ fontFamily: "Dancing Script, cursive" }} data-font="Dancing+Script">Dancing Script</option> */}
                                            <option
                                                value="pacifico, cursive"
                                                style={{ fontFamily: "pacifico, cursive" }}
                                                data-font="Pacifico"
                                            >
                                                Pacifico
                                            </option>
                                        </select>
                                    </div>

                                    <div className="btn-group-h-2">
                                        <div className="btn-group-h-col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="material-icons">
                                                            format_size
                                                        </i>
                                                    </span>
                                                </div>
                                                <input className="form-control" type="number" value={selectedFontSize} name='fontSize' onChange={e => this.handleChange(e)} style={{ marginTop: 0 }} />
                                            </div>
                                        </div>
                                        <div className="btn-group-h-col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="material-icons">
                                                            format_line_spacing
                                                        </i>
                                                    </span>
                                                </div>
                                                <select className="form-control" value={selectedLineHeight || "1.0"} name='lineHeight' onChange={e => this.handleChange(e)}>
                                                    <option value="0.1">0.1x</option>
                                                    <option value="0.2">0.2x</option>
                                                    <option value="0.4">0.4x</option>
                                                    <option value="0.6">0.6x</option>
                                                    <option value="0.8">0.8x</option>
                                                    <option value="1.0">1x</option>
                                                    <option value="1.15">1.15x</option>
                                                    <option value="1.5">1.5x</option>
                                                    <option value="2">2x</option>
                                                    <option value="3">3x</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="btn-group-h">
                                        <label class="btn-input-con" for="checkBold" onChange={(e) => this.handleChange(e)}>
                                            <input
                                                class="btn-input"
                                                id="checkBold"
                                                type="checkbox"
                                                name="bold"
                                                value="bold"
                                                checked={selectedFontBold}
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_bold</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="checkItalic" onChange={(e) => this.handleChange(e)}>
                                            <input
                                                class="btn-input"
                                                id="checkItalic"
                                                type="checkbox"
                                                name="italic"
                                                value="italic"
                                                checked={selectedFontItalic}
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_italic</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="checkUnderline" onChange={(e) => this.handleCheckBox(e)}>
                                            <input
                                                class="btn-input"
                                                id="checkUnderline"
                                                type="checkbox"
                                                name="textDecoration"
                                                checked={selectedTextUnderLine === "underline" ? true : false}
                                                value="underline"
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_underlined</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="radioAlignLeft">
                                            <input
                                                class="btn-input"
                                                id="radioAlignLeft"
                                                type="radio"
                                                name="align"
                                                checked={selectedTextAlignment === "left" ? true : false}
                                                value="left"
                                                onChange={e => this.handleChange(e)}
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_align_left</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="radioAlignCenter" >
                                            <input
                                                class="btn-input"
                                                id="radioAlignCenter"
                                                type="radio"
                                                checked={selectedTextAlignment === "center" ? true : false}
                                                name="align"
                                                onChange={e => this.handleChange(e)}
                                                value="center"
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_align_center</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="radioAlignRight">
                                            <input
                                                class="btn-input"
                                                id="radioAlignRight"
                                                type="radio"
                                                checked={selectedTextAlignment === "right" ? true : false}
                                                onChange={e => this.handleChange(e)}
                                                name="align"
                                                value="right"
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_align_right</i>
                                            </span>
                                        </label>
                                    </div>



                                    <div className="tools-block-title-sub">Font Color</div>
                                    <div class="btn-group-h-2 font-color-group">
                                        <div class="btn-group-h-col">
                                            <div class="dropdown dropleft">
                                                <button
                                                    id="addColorElement"
                                                    class="btn-tools btn-fill"
                                                    onClick={(e) => {
                                                        if (e.stopPropagation) {
                                                            e.stopPropagation();   // W3C model
                                                        } else {
                                                            e.cancelBubble = true; // IE model
                                                        } this.setState({ showColorPalette: !showColorPalette })
                                                    }}
                                                // data-toggle="dropdown"
                                                >
                                                    <span
                                                        id="picked-color"
                                                        class="picked-color"
                                                        style={{ backgroundColor: selectedColor }}
                                                    ></span>
                                                    <span
                                                        id="picked-color-value"
                                                        class="picked-color-value"
                                                    >
                                                        {showColorPalette ? "Click To Hide" : selectedColor}
                                                    </span>
                                                </button>
                                                {/*<div class="dropdown-menu color-palette">
                                                    <RenderColorPalette handleColorChange={this.handleColorChange} SketchColorChange={this.handleUserColorChange} />
                                                </div>*/}
                                                {showColorPalette &&
                                                    <div style={{ position: 'absolute', top: 40, zIndex: 2 }}>
                                                        {/* <RenderColorPalette handleColorChange={this.handleColorChange} /> */}
                                                        <SketchPicker onChange={this.handleUserColorChange} color={selectedColor} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tools-block-title">Layer</div>

                                    <div className="btn-group-h">
                                        <button
                                            id="deleteLayer"
                                            className="btn-tools"
                                            onClick={this.deleteText}
                                        >
                                            <i className="material-icons">delete_outline</i>
                                        </button>
                                        <button
                                            id="checkAlignV"
                                            className="btn-tools"
                                            onClick={() => this.align("vCenter")}
                                        >
                                            <i className="material-icons">vertical_align_center</i>
                                        </button>
                                        <button
                                            id="checkAlignH"
                                            className="btn-tools"
                                            onClick={() => this.align("hCenter")}
                                        >
                                            <i className="material-icons">vertical_align_center</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="tools-footer d-block">
                                    <button
                                        className="btn btn-publish"
                                        data-toggle="modal"
                                        data-target="#download-meme-modal"
                                        onClick={() => this.processImage()}
                                    >
                                        {" "}
                                        Download
                                    </button>
                                    <button
                                        className="btn btn-publish"
                                        onClick={() => {
                                            this.setState({
                                                update: true
                                            });
                                        }}>
                                        save to my invitations
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

                <div className="modal fade" id="new-meme-modal" data-backdrop="static">
                    <div className="modal-dialog modal-upload-image">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Upload Image</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body modal-form">
                                <div className="upload-image-con">
                                    <div className="upload-image-inner">
                                        <div>
                                            <div className="image-icon">
                                                <image src="images/image-icon.svg" alt="" />
                                            </div>
                                            <label for="upload-image">
                                                <strong>Click here</strong> to{" "}
                                                <span className="text-before">upload image</span>{" "}
                                                <span className="text-after">change image</span>
                                            </label>
                                            <input
                                                type="file"
                                                name=""
                                                id="upload-image"
                                                className="box__file"
                                                data-multiple-caption=""
                                            />
                                        </div>
                                    </div>

                                    <div className="uploaded_image_con">
                                        <div className="image-for-desktop">
                                            <div className="uploaded_image"></div>
                                            <p>Desktop view</p>
                                        </div>
                                        <div className="image-for-mobile">
                                            <div className="uploaded_image"></div>
                                            <p>Mobile view</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="download-meme-modal" data-backdrop="static">
                    <div className="modal-dialog modal-download-image">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={() => {
                                    this.closeDownload();
                                }}
                            >
                                &times;
                            </button>

                            <div className="modal-body modal-form">
                                {/* {console.log("this.state.loader", this.state.imgBase64)} */}
                                <div className="download-image-con">
                                    {this.state.loader === true ? (
                                        <div className="image-showcase">
                                            <div
                                                id="image-showcase-inner"
                                                className="image-showcase-inner"
                                            >
                                                {/* <img src="images/loader.png"/> */}
                                                {/* <SmallLoader style={{ width: "200%" }}></SmallLoader> */}
                                                {/* <img style={{ width: "200%" }} src="images/loaderGif.gif" /> */}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="image-showcase">
                                            <div
                                                id="image-showcase-inner"
                                                className="image-showcase-inner"
                                            ></div>
                                        </div>
                                    )}
                                    <div className="donload-options">
                                        <h4>Download</h4>

                                        <div>
                                            {
                                                this.state.mobile ?

                                                    <a
                                                        // href={this.state.imgBase64}
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: "/download",
                                                                state: {
                                                                    dataUrl: this.state.imgBase64
                                                                }
                                                            });
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#download-meme-modal"
                                                        style={linkStyle}
                                                        className="download-meme primary-action-outline action"
                                                        download="image"
                                                    >
                                                        DOWNLOAD INVITATION

                                                    </a> :
                                                    <a
                                                        href={this.state.imgBase64}
                                                        style={linkStyle}
                                                        className="download-meme primary-action-outline action"
                                                        download="image"
                                                    >
                                                        DOWNLOAD INVITATION

                                                    </a>
                                            }
                                            <a
                                                href
                                                data-dismiss="modal"
                                                onClick={() => this.makePayment()}
                                                style={{ cursor: "pointer" }}
                                                className="download-meme primary-action action"
                                            >
                                                Download without watermark -{" $"}
                                                {(this.state.img && typeof (this.state.img) !== 'string') ?
                                                    this.state.img.price
                                                    : this.state.price}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal show={this.state.maxlimit}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "auto" }}>Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: "center" }}>
                        {" "}
                        Maximum invitation limit reached{" "}
                    </Modal.Body>
                    <Modal.Footer>

                        <Button
                            variant="primary"
                            onClick={() => this.setState({ maxlimit: false })}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.MemeSaved}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "auto" }}>Meme Saved</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: "center" }}>
                        {" "}
                        Meme Saved Successfully{" "}
                    </Modal.Body>
                    <Modal.Footer>

                        <Button
                            variant="primary"
                            onClick={() => this.setState({ MemeSaved: false })}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.MemeNotSaved}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "auto" }}>Meme Not Saved</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: "center" }}>
                        {" "}
                        Maximum Limit Reached{" "}
                    </Modal.Body>
                    <Modal.Footer>

                        <Button
                            variant="primary"
                            onClick={() => this.setState({ MemeNotSaved: false })}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.update} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ marginLeft: "auto" }}>
                            Add to my invitation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ alignSelf: "center" }}>
                        {" "}
                        Do you want to save this invitation?{" "}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={this.SaveMymemes}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.paymentSucess} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            alignSelf: "center",
                            padding: "1rem"
                        }}
                    >
                        {this.state.paymentstatus
                            ? this.state.zeroDownload === true
                                ? " Your image is downloading ..!"
                                : "Payment successful. Your image is downloading ..!"
                            : "Payment has been failed"}
                    </Modal.Body>
                    {!this.state.mobile ?
                        <Modal.Footer>
                            {this.state.paymentstatus ? (
                                <button className="btn_success" onClick={this.handleClose}>
                                    ok
                                </button>) : (
                                <button className="btn_fail" onClick={this.handleClose}>
                                    ok
                                </button>
                            )}
                        </Modal.Footer> :
                        <Modal.Footer>
                            {this.state.paymentstatus ? (
                                <Spinner animation="grow" variant="success" />
                            ) : (
                                <button className="btn_fail" onClick={this.handleClose}>
                                    ok
                                </button>
                            )}
                        </Modal.Footer>
                    }
                </Modal>
                <Payment
                    showPayment={this.state.showPayment}
                    hidePayment={this.hidePayment}
                    paymentData={this.state.paymentData}
                    downloadImage={this.downloadImage}
                    setLoader={this.setLoader}
                />
            </>
        );
    }
}

const mapStateToProps = state => (
    // console.log("Editorstate", state),
    {
        getAllMemeAction: state.homeindex,
        fetchFontAction: state.homeindex,
        freeDownloadAction: state.homeindex
    }
);

export default withRouter(connect(mapStateToProps, { ...homeIndexAction })(EditorView));
