import { useEffect } from "react";
import { createPortal } from "react-dom";

const Modal = (props) => {
    let modalRoot = document.getElementById("commonModal");
    const modalElement = document.createElement("div");
    const backDropElement = document.createElement("div");
    backDropElement.classList.add('modal-backdrop');
    backDropElement.classList.add('show');
    useEffect(() => {
        modalRoot.appendChild(modalElement);
        document.body.appendChild(backDropElement);
        return () => {
            modalRoot.removeChild(modalElement);
            document.body.removeChild(backDropElement);
        }

    });
    return createPortal(props.body, modalElement);
};

export default Modal;