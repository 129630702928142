import React from "react";
import { Route } from "react-router-dom";

import Footer from '../home/Footer';

const CommonRoute = ({ component: Component, ...rest }) => {
    return (
        <Route


            {...rest}
            render={props =>
                <main class="main-container">
                    <div class="outer-cover">
                        <div class="banner-strip text-center">
                            <nav class="d-flex align-items-center ">
                                <a href=""><img src="images/main-logo.png" alt="" /></a>
                                <a href="" class="ml-auto action">MY MEMES</a>
                            </nav>
                            <header class="banner-content d-flex align-items-center flex-column justify-content-center">
                                <h1>KEEP SMILING.. Create your own Memes</h1>
                                <p>Over 6,150,000 Memes.  Download what you want</p>
                                <a href="" class="create-meme primary-action action"><span></span>DESIGN YOUR OWN MEME</a>
                            </header>
                        </div>
                        <div class="category-strip d-flex text-center align-items-center">
                            <div class="container-fluid">
                                <a href="/" class={rest.location.pathname === '/' ? "category active action" : "category action"}>ALL INVITATIONS</a>
                                <a href="/category/vort" class={rest.location.pathname === '/category/vort' ? "category active action" : "category action"}>Vort</a>
                                <a href="/category/" class="category action">Vort</a>
                                <a href="/category/" class="category action">Wedding</a>
                                <a href="/category/" class="category action">Kiddush</a>
                                <a href="/category/" class="category action">Vort</a>
                                <a href="/category/" class="category action">Bar Mitzvah</a>
                                <a href="/category/" class="category action">Bar Mitzvah</a>
                                <a href="/category/" class="category action">BIRTH DAY PARTY</a>
                            </div>
                        </div>
                        <div class="modal  fade custom-modal" id="edit-modal">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">BIRTH DAY PARTY</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="img-cover position-relative">
                                            <img src="images/Image 115.png" alt="" />
                                            <div class="edit-row d-flex align-items-center justify-content-center">
                                                <a href="">
                                                    <span></span>
                                                    EDIT THIS MEME
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-cover">
                            <Component />
                        </div>
                        <Footer />
                    </div>
                </main>
            }
        />
    );
};

export default CommonRoute;
