import './fonts/Lobster-Regular.ttf';
import './fonts/DancingScript-Bold.ttf';
import './fonts/Bellota-Regular.ttf';
import './fonts/Roboto-Medium.ttf';
import './fonts/Oswald-Regular.ttf';
import './fonts/LibreBaskerville-Regular.ttf';
import './fonts/Montserrat-Regular.ttf';
import './fonts/Merriweather-Regular.ttf';
import './fonts/PlayfairDisplay-Regular.ttf';
import './fonts/OpenSansCondensed-Light.ttf';
import './fonts/Nunito-Regular.ttf';
import './fonts/TitilliumWeb-Regular.ttf';
import './fonts/Quicksand-Regular.ttf';
import './fonts/JosefinSans-Regular.ttf';
import './fonts/IndieFlower-Regular.ttf';
import './fonts/Pacifico-Regular.ttf';



import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './components/store/index' 

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
